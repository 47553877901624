import React from "react";
import Icon from "@material-ui/core/Icon";
import "./messages.css";
import "./messageLoader.css";
import PropTypes from "prop-types";

function Messages(props) {
  const { loader, speaker, text, messageBotIndex } = props;

  let msg;
  let loaderDiv = false;
  if (loader === true) {
    loaderDiv = (
      <div className="loaderMessageBox">
        <div className="messageImg">
          <img
            className="message_bot_logo"
            // src="https://surveyassistant.online//webtheme/img/logouser.png"
            src={require("../../../../assests/images/chatbot/SomnologyLogoBotMessageIcon.png")}
            alt="bot"
          ></img>
        </div>
        <div className="messageTextBot loaderMessage">
          <div class="canvas">
            <div class="blue ball" />
            <div class="red ball" />
            <div class="yellow ball" />
          </div>
        </div>
      </div>
    );
  } else {
    loaderDiv = null;
  }
  if (speaker === "bot" && messageBotIndex === 0) {
    msg = (
      <div>
        <div className="messageImg">
          <img
            // style={{background:'#e6e6e6'}}
            className="message_bot_logo"
            // src="https://surveyassistant.online//webtheme/img/logouser.png"
            src={require("../../../../assests/images/chatbot/SomnologyLogoBotMessageIcon.png")}
            alt="bot"
          ></img>
        </div>
        <div className="messageTextBot messageTextBotWithIcon">
          <div id="botMsg" className="bot-message z-depth-1">
            <div className="black-text">{text}</div>
          </div>
        </div>
      </div>
    );
  } else if (speaker === "bot") {
    msg = (
      <div className="messageTextBot ">
        <div id="botMsg" className="bot-message z-depth-1">
          <div className="black-text">{text}</div>
        </div>
      </div>
    );
  } else if (speaker === "me") {
    msg = (
      <div>
        <div className="messageImgICONUser">
          <Icon className="userPersonLogo">person</Icon>
          {/* <img
            src={require("../../../../assests/images/chatbot/CustomerIcon.svg")}
          /> */}
          {/* <object data="../../../../assests/images/chatbot/CustomerIcon.svg" /> */}
          {/* <img
            className="message_bot_logo"
            style={{float:'right'}}
            // src={require("../../../../assests/images/chatbot/msd-01-icon.png")}
            src="https://www.cogniaim.com/webtheme/img/products/userLogo.png"
            alt="bot"
          ></img> */}
        </div>
        <div className="messageTextUser">
          <div className="user-message z-depth-1">
            <div className="white-text">{text}</div>
          </div>
          {/* <div className="timmer">{time}</div> */}
        </div>
      </div>
    );
  }

  return (
    <div className={speaker === "bot" ? "Botmessages" : "Usermessages"}>
      {msg}
      {loaderDiv}
    </div>
  );
}

Messages.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.instanceOf(Messages),
  ]),
  loader: PropTypes.bool,
  speaker: PropTypes.string,
  time: PropTypes.string,
};

export default Messages;
