const initialState = {
  messages: JSON.parse(localStorage.getItem("chatConvo"))
    ? JSON.parse(localStorage.getItem("chatConvo"))
    : [
        {
          speaks: "bot",
          loader: false,
          msg: {
            platform: "PLATFORM_UNSPECIFIED",
            text: {
              text: [
                "Welcome to Sleep Disorder Diagnostic AI Assistant. I can help diagnose your sleep disorder.",
              ],
            },
            message: "text",
          },
          botMessageIndex: 0,
        },
        {
          speaks: "bot",
          loader: false,
          msg: {
            platform: "PLATFORM_UNSPECIFIED",
            text: { text: ["Can you answer a few questions for me?"] },
            message: "text",
          },
          botMessageIndex: 1,
        },
        {
          speaks: "bot",
          loader: false,
          msg: {
            platform: "PLATFORM_UNSPECIFIED",
            payload: {
              fields: {
                quick_replies: {
                  listValue: {
                    values: [
                      {
                        structValue: {
                          fields: {
                            size: {
                              stringValue: "small",
                              kind: "stringValue",
                            },
                            payload: {
                              stringValue: "name",
                              kind: "stringValue",
                            },
                            text: {
                              stringValue: "Yes",
                              kind: "stringValue",
                            },
                            event: {
                              stringValue: "NAME",
                              kind: "stringvalue",
                            },
                          },
                        },
                        kind: "structValue",
                      },
                      {
                        structValue: {
                          fields: {
                            size: {
                              stringValue: "small",
                              kind: "stringValue",
                            },
                            payload: {
                              stringValue: "Declined",
                              kind: "stringValue",
                            },
                            text: {
                              stringValue: "No",
                              kind: "stringValue",
                            },
                            event: {
                              stringValue: "DECLINED",
                              kind: "stringvalue",
                            },
                          },
                        },
                        kind: "structValue",
                      },
                    ],
                  },
                  kind: "listValue",
                },
              },
            },
            message: "payload",
          },
          botMessageIndex: 2,
        },
      ],
  inputFieldType: "text",
};

const dfQueryReducer = (state = initialState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "CHANGE_DF_TEXT_QUERY_FULFILLMENT":
      console.log("MESSAGE_FULFILLMENT");

      return {
        ...state,
        inputFieldType: action.inputFieldType,
        messages: [...action.payload],
      };
    case "CHANGE_DF_TEXT_QUERY_WEBHOOK":
      console.log("MESSAGE_WEBHOOK");
      return {
        ...state,
        messages: [...action.payload],
      };
    case "CHANGE_DF_EVENT_QUERY":
      console.log("MESSAGE_EVENT_QUERY");
      return {
        ...state,
        messages: [...action.payload],
      };
    case "CHANGE_MESSAGE_ARRAY":
      console.log("MESSAGE_ARRAY_UPDATED");
      return {
        ...state,
        messages: [...action.payload],
      };

    case "Clear_Conversation":
      return {
        ...state,
        messages: [...action.payload],
      };
  }
  return state;
};
export default dfQueryReducer;
