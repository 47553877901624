import styled from "styled-components";
import ellipse from "../../../../assests/images/chatbot/ellipse.png";
export const Wrapper = styled.div`
	background: url(${ellipse});
	background-size: contain;
	background-repeat: no-repeat;
	position: relative;
	max-width: 100%;
	/* width: 90%; */
	padding: 30px 20px;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: center;
	align-items: center;
	/* height: 100vh; */
	overflow: hidden;
	/* overflow: hidden; */
	h1 {
		color: #1ca7ec;
		margin: 24px 0 24px 0;
	}

	@media screen and (max-width: 460px) {
		height: auto;
		background-position: center;
		h1 {
			font-size: 24px;
		}
	}
`;

export const HowItWorksDetails = styled.div`
	width: 80%;
	display: flex;
	margin-top: 24px;
	justify-content: space-around;
	@media screen and (max-width: 768px) {
		align-items: center;
	}
	@media screen and (max-width: 468px) {
		width: 95%;
		flex-direction: column;
	}
	@media screen and (min-width: 468px) and (max-width: 640px) {
		width: 95%;
		flex-direction: column;
	}
`;
export const TimeLine = styled.div``;
export const Bubble = styled.div`
	position: absolute;
	top: 10%;
	left: -300px;
	height: 700px;
	width: 700px;
	background: #cfffff;
	border-radius: 50%;
	z-index: -10;
	@media screen and (max-width: 468px) {
		display: none;
	}
`;

export const ListWrapper = styled.div`
	position: relative;
	width: 90%;
	margin: 30px auto;
	/* border: 1px solid black; */
`;
export const ListItemWrapper = styled.div`
	margin-top: 60px;
	position: relative;
	display: flex;
	justify-content: start;
	align-items: center;
	font-size: 1.6rem;
	@media screen and (max-width: 468px) {
		font-size: 16px;
		border: 1px solid rgba(10, 10, 10, 0.8);
		/* padding: 10px; */
		border-radius: 10px;
	}
	@media screen and (min-width: 468px) and (max-width: 650px) {
		font-size: 16px;
		border: 1px solid rgba(10, 10, 10, 0.8);
		/* padding: 10px; */
		border-radius: 10px;
	}
`;

export const ListBullet = styled.div`
	float: left;
	margin-right: 20px;
	background: #1ca7ec;
	height: 50px;
	width: 50px;
	line-height: 50px;
	border-radius: 100px;
	font-weight: 500;
	color: white;
	text-align: center;
	font-size: 1.5rem;
	@media screen and (max-width: 468px) {
		/* display: none; */
		border-radius: 10px 0 0 10px;
		height: 65px;
		width: 65px;
		padding: 10px;
		line-height: 40px;
	}
`;

export const ListItem = styled.div`
	font-weight: 500;
	width: 400px;
	@media screen and (max-width: 468px) {
		padding: 10px;
	}
`;

export const ScreenRecord = styled.img`
	/* height: 600px; */
	width: 300px;
	margin: 0 40px;
	/* border: 3px solid #3ea7ec; */
	border-radius: 10px;
	@media screen and (max-width: 468px) {
		width: 280px;
	}
	@media screen and (max-width: 468px) {
		/* width: 230px; */
		margin: 10px auto;
	}

	@media screen and (max-width: 320px) {
		width: 270px;
	}
`;

export const WhiteLine = styled.div`
	background: #cfffff;
	z-index: -1;
	top: 38px;
	bottom: 0;
	width: 8px;
	height: 130%;
	position: absolute;
	left: 19px;
	@media screen and (max-width: 468px) {
		display: none;
	}
`;

export const BlueLine = styled.div`
	background: #1ca7ec;
	z-index: -1;
	width: 6px;
	height: 100%;
	position: absolute;
	left: 19px;
	top: 25px;
	@media screen and (max-width: 468px) {
		display: none;
	}
`;

export const BulletStyle = styled.img`
	width: 400px;
	/* height: 400px; */
	margin: 0 40px;
	@media screen and (max-width: 468px) {
		width: 230px;
		margin: 0 auto 20px auto;
	}

	@media screen and (max-width: 768px) {
		width: 300px;
	}
`;
export const BulletStyleParent = styled.div`
	width: 70%;
	@media screen and (max-width: 468px) {
		width: 100%;
		margin: 20px auto;
	}
`;
