const initialState = {
  Apneas: [
    {
      question: "Apneas Witnessed",
      answer: "",
    },
    {
      question: "Gasping/Choking arousals",
      answer: "",
    },
  ],
  Snoring: [
    {
      question: "Frequency",
      answer: "",
    },
    {
      question: "Volume",
      answer: "",
    },
    {
      question: "Snort Arousals",
      answer: "",
    },
    {
      question: "Worsening Factors",
      answer: "",
    },
  ],
};

const formReducer = (state = initialState, action) => {
  const formTopic = action?.payload?.formTopic;
  const formQuestion = action?.payload?.formQuestion;
  const formAnswer = action?.payload?.formAnswer;
  switch (action.type) {
    case "SET_FORM_ANSWER":
      console.log("ACTION in form reducer", action);
      if (formTopic == "Apneas") {
        console.log("in apneas");
        return {
          ...state,
          Apneas: state.Apneas.map((question, i) =>
            question.question === formQuestion
              ? { ...question, answer: formAnswer }
              : question
          ),
        };
      } else if (formTopic == "Snoring") {
        console.log("in snoring");
        return {
          ...state,
          Snoring: state.Snoring.map((question, i) =>
            question.question === formQuestion
              ? { ...question, answer: formAnswer }
              : question
          ),
        };
      }
  }
  return state;
};
export default formReducer;
