import React from "react";
// import { ListItem } from "react-bootstrap/lib/media";
import {
	ListItem,
	Wrapper,
	ListItemWrapper,
	ListTitle,
	ListWrapper,
	ListBullet,
	BlueLine,
	ListText,
	WhiteLine,
	Bubble,
	ScreenRecord,
	TimeLine,
	HowItWorksDetails,
	BulletStyle,
	BulletStyleParent,
} from "./HowItWorks.style";
const HowItWorks = () => {
	return (
		<Wrapper>
			{/* <Bubble></Bubble> */}

			<h1>How It Works</h1>
			<HowItWorksDetails className="Details">
				<TimeLine>
					{/* <ListWrapper> */}

					{/* <ListItemWrapper>
							<ListBullet>1</ListBullet>
							<ListItem>Select Your Problem</ListItem>
						</ListItemWrapper>
						<ListItemWrapper>
							<ListBullet>2</ListBullet>
							<ListItem>Answer a few questions</ListItem>
						</ListItemWrapper>
						<ListItemWrapper>
							<ListBullet>3</ListBullet>
							<ListItem>View your potential sleep disorder</ListItem>
						</ListItemWrapper>
					</ListWrapper> */}
					<BulletStyleParent>
						<BulletStyle
							src={require("../../../../assests/images/chatbot/newBullets.png")}
						/>
					</BulletStyleParent>
				</TimeLine>
				<ScreenRecord
					src={require("../../../../assests/images/chatbot/medicine.gif")}
					autoPlay
					muted
					loop
				></ScreenRecord>
			</HowItWorksDetails>
		</Wrapper>
	);
};

export default HowItWorks;
