import styled from "styled-components";

export const TopHeader = styled.div`
	position: relative;
	max-width: 100%;
	padding: 10px 60px;
	margin: 0px auto;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	background-color: #3ea7ec;
	@media (max-width: 460px) {
		justify-content: center;
		#heading {
			display: none;
		}
	}
`;

export const LogoImage = styled.div`
	position: relative;
	margin: auto 40px;
	display: flex;
	flex-direction: column;
	background-color: #3ea7ec;
	text-align: center;
	width: 50%;
	img {
		max-width: 100px;
		/* height: 60px; */
	}
	@media screen and (max-width: 460px) {
		margin: 0 100px;
		img {
			justify-content: center;
			align-items: center;
			width: 120px;
		}
	}
`;

export const LogoHeading = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center; ;
`;

export const LogoMainHeading = styled.div`
	position: relative;
	p {
		color: #fff;
		font-size: 16px;
		margin-bottom: 0;
	}
`;

export const LogoSubHeading = styled.div`
	margin-top: 10px;
	position: relative;
	p {
		color: #fff;
		font-size: 12px;
		margin-bottom: 0;
	}
`;

export const ExtraSpace = styled.div`
	position: relative;
	width: 100%;
	padding: 5px 100px;
	margin: 0px auto;
	display: flex;
	background-color: #3ea7ec;
`;
