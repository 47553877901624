import React from "react";
import Button from "react-bootstrap/Button";
import "./quickReply.css";
import { connect } from "react-redux";
import * as actionTypes from "../../../../reduxStore/actions/action";

function QuickReplyButton(props) {
  function redirect(link) {
    window.open(link, "_blank");
  }

  async function onclickHandler(buttonText, event) {
    console.log("Buttontext , event ", buttonText, event);
    if (event) {
      props.changeMessageArray();
      props.dfEventQuery(buttonText);
      await props.userTextQuery(event);
    } else {
      props.changeMessageArray();
      props.userTextQuery(buttonText);
      await props.dfTestQuery(buttonText);
    }
  }

  return props.payload.map((quickReplies, index) => {
    if (
      quickReplies.structValue &&
      quickReplies.structValue.fields &&
      quickReplies.structValue.fields.link &&
      quickReplies.structValue.fields.link.stringValue
    ) {
      return (
        <div
          id={quickReplies.structValue.fields.payload.stringValue}
          key={index}
          className={
            quickReplies.structValue.fields.size.stringValue === "large"
              ? "quickButtonDiv col-md-11 col-11"
              : quickReplies.structValue.fields.size.stringValue === "small"
              ? "quickButtonDiv col-md-3 col-3"
              : "quickButtonDiv col-md-6 col-6"
          }
        >
          <Button
            className="quickButton col-md-12 col-12 "
            onClick={() =>
              redirect(quickReplies.structValue.fields.link.stringValue)
            }
          >
            {quickReplies.structValue.fields.text.stringValue}
          </Button>
        </div>
      );
    } else if (
      quickReplies.structValue &&
      quickReplies.structValue.fields &&
      quickReplies.structValue.fields.event &&
      quickReplies.structValue.fields.event.stringValue
    ) {
      return (
        <div
          id={quickReplies.structValue.fields.payload.stringValue}
          key={index}
          className={
            quickReplies.structValue.fields.size.stringValue === "large"
              ? "quickButtonDiv col-md-11 col-11"
              : quickReplies.structValue.fields.size.stringValue === "small"
              ? "quickButtonDiv col-md-4 col-4"
              : "quickButtonDiv col-md-6 col-6"
          }
        >
          <Button
            className="quickButton col-md-12 col-12 "
            onClick={() =>
              onclickHandler(
                quickReplies.structValue.fields.event.stringValue,
                quickReplies.structValue.fields.text.stringValue
              )
            }
          >
            <div className="button_text">
              {quickReplies.structValue.fields.text.stringValue}
            </div>
          </Button>
        </div>
      );
    } else {
      return (
        <div
          id={quickReplies.structValue.fields.payload.stringValue}
          key={index}
          className={
            quickReplies.structValue.fields.size.stringValue === "large"
              ? "quickButtonDiv col-md-11 col-11"
              : quickReplies.structValue.fields.size.stringValue === "small"
              ? "quickButtonDiv col-md-4 col-4"
              : "quickButtonDiv col-md-6 col-6"
          }
        >
          <Button
            className="quickButton col-md-12 col-12 "
            onClick={() =>
              onclickHandler(quickReplies.structValue.fields.text.stringValue)
            }
          >
            <div className="button_text">
              {quickReplies.structValue.fields.text.stringValue}
            </div>
          </Button>
        </div>
      );
    }
  });
}

const mapStateToProps = (state) => {
  return {
    messages: state.dfQuery.messages,
  };
};

export default connect(mapStateToProps, actionTypes)(QuickReplyButton);
