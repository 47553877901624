import React from "react";
import "./cardView.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actionTypes from "../../../../reduxStore/actions/action";
const CardView = (props) => {
  const { payload, width } = props;
  function redirect(link) {
    window.open(link, "_blank");
  }
  async function onclickHandler(buttonText) {
    props.changeMessageArray();
    props.userTextQuery(buttonText);
    await props.dfTestQuery(buttonText);
  }
  return (
    <div>
      { props.messageBotIndex === 0 ? (
        <div className="messageImg">
          <img
            // style={{ background: "#e6e6e6" }}
            className="message_bot_logo"
            src="https://surveyassistant.online//webtheme/img/logouser.png"
            alt="bot"
          ></img>
        </div>
      ) : null }

      <div className="card_view_parent ">
        <div id="chatbot_body" className="card_main_body ">
          <div className="allCardParent" style={{ width: width }}>
            {payload.map((cardData, index) => {
              return (
                <div
                  id={cardData.structValue.fields.payload.stringValue}
                  className="singleCardParent"
                  key={index}
                >
                  <div style={{ height: 70 }}>
                    <img
                      className="card_image"
                      alt={cardData.structValue.fields.payload.stringValue}
                      src={cardData.structValue.fields.image.stringValue}
                    ></img>
                  </div>
                  <div className="card_content">
                    <h5 className="h5CardContent">
                      {cardData.structValue.fields.cardTitle.stringValue}
                    </h5>
                  </div>
                  {cardData.structValue.fields &&
                  cardData.structValue.fields.type &&
                  cardData.structValue.fields.type.stringValue ===
                    "withButton" ? (
                    <div
                      className="card_content_button"
                      onClick={() =>
                        redirect(cardData.structValue.fields.link.stringValue)
                      }
                    >
                      <h5 className="h5CardContent_button">
                        {cardData.structValue.fields.button.stringValue}
                      </h5>
                    </div>
                  ) : cardData.structValue.fields &&
                    cardData.structValue.fields.type &&
                    cardData.structValue.fields.type.stringValue ===
                      "withButtonQuery" ? (
                    <div
                      className="card_content_button"
                      onClick={() =>
                        onclickHandler(
                          cardData.structValue.fields.button.stringValue
                        )
                      }
                    >
                      <h5 className="h5CardContent_button">
                        {cardData.structValue.fields.button.stringValue}
                      </h5>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

CardView.propTypes = {
  payload: PropTypes.array,
  width: PropTypes.number,
};

export default connect(null, actionTypes)(CardView);
