import React, { useState, useEffect } from "react";
import Icon from "@material-ui/core/Icon";
//import MicIcon from '@material-ui/icons/Mic';
import "./footer.css";
import { connect } from "react-redux";
import * as actionTypes from "../../../reduxStore/actions/action";
import PropTypes from "prop-types";

const Footer = (props) => {
	const [input, setInput] = useState("");

	useEffect(() => {
		if (props.inputFieldType === "email") {
			if (
				input.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ||
				input.includes("help") === true
			) {
				props.checkEmailValidation("Type a message...", true, "#ffffff");
			} else {
				if (input) {
					props.checkEmailValidation(
						"Please provide email in correct format.",
						false,
						"#ffb3b3"
					);
				} else {
					props.checkEmailValidation("Type a message...", true, "#ffffff");
				}
			}
		} else {
			props.checkEmailValidation("Type a message...", true, "#ffffff");
		}
	}, [props.inputFieldType, input]);

	function handleKeyPress(e) {
		if (
			e.key === "Enter" &&
			input !== "" &&
			props.checkEmailValidateObject.inputState === true
		) {
			props.changeMessageArray();
			props.userTextQuery(input);
			props.dfTestQuery(input);
			setInput("");
		}
	}
	function onClickButton() {
		if (input !== "" && props.checkEmailValidateObject.inputState === true) {
			props.changeMessageArray();
			props.userTextQuery(input);
			props.dfTestQuery(input);
			setInput("");
		}
	}

	function onClickHelp(value) {
		props.changeMessageArray();
		props.userTextQuery(value);
		props.dfEventQuery(value);
	}

	return (
		<div className="footerParent">
			{/* {!localStorage.getItem("detailsReceived") && (
        <div className="footerHelpButton" onClick={() => onClickHelp("HELP")}>
          Help
        </div>
      )} */}

			<div className="poweredByFooter">
				<Icon style={{ verticalAlign: "middle" }}>flash_on_icon</Icon>
				<a
					target="_blank"
					rel="noopener noreferrer"
					style={{ color: "#1c4fc3", fontWeight: 600, fontSize: 14 }}
					href="https://cogniaim.com/"
				>
					By Cogniaim
				</a>
			</div>

			<div
				className="row footerTextInput"
				style={{
					background: props.checkEmailValidateObject.setFooterColor,
					// display: "none",
				}}
			>
				<div className="col-md-10 sendMessage col-10">
					<input
						className="sendInoutBar"
						placeholder={props.checkEmailValidateObject.placeholder}
						value={input}
						// display={props.messages[props.messages.length - 1].msg.payload}
						// disabled={props.messages[props.messages.length - 1].msg.payload}
						disabled={localStorage.getItem("detailsReceived")}
						type="text"
						onChange={(evt) => {
							setInput(evt.target.value);
						}}
						onKeyPress={(e) => {
							handleKeyPress(e);
						}}
					/>
				</div>

				<div
					className="col-md-2 col-2 sendMessageButton"
					onClick={onClickButton}
				>
					<Icon>send</Icon>
				</div>
			</div>
		</div>
	);
};

Footer.propTypes = {
	changeMessageArray: PropTypes.func,
	userTextQuery: PropTypes.func,
	dfTestQuery: PropTypes.func,
	inputFieldType: PropTypes.string,
	checkEmailValidateObject: PropTypes.object,
	// messages:PropTypes.array
};

const mapStateToProps = (state) => {
	return {
		inputFieldType: state.dfQuery.inputFieldType,
		checkEmailValidateObject: state.chatbotReducer.checkEmailValidateObject,
		messages: state.dfQuery.messages,
	};
};

export default connect(mapStateToProps, actionTypes)(Footer);
