import React from "react";
import "./FormView.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actionTypes from "../../../../reduxStore/actions/action";
import { Form } from "react-bootstrap";
import { Input } from "@material-ui/core";
import Button from "react-bootstrap/Button";

const FormView = (props) => {
  const { payload, width, categoryName, ButtonValue } = props;

  console.log("PAYLOAD", payload);
  function redirect(link) {
    window.open(link, "_blank");
  }

  async function onclickHandler(buttonText) {
    props.changeMessageArray();
    props.userTextQuery(buttonText);
    await props.dfTestQuery(buttonText);
  }

  return (
    <div className="Botmessages">
      {props.messageBotIndex === 0 ? (
        <div className="messageImg">
          <img
            // style={{ background: "#e6e6e6" }}
            className="message_bot_logo"
            src="https://surveyassistant.online//webtheme/img/logouser.png"
            alt="bot"
          ></img>
        </div>
      ) : null}

      <div className="card_view_parent ">
        <div
          className="card_main_body formParent"
          style={{ "margin-right": "95px" }}
        >
          <div
            style={{
              width: width,
              border: "4px solid #f89b25",
              "border-radius": "15px",
            }}
          >
            <h2 className="formHeading">{categoryName}</h2>
            {payload.map((cardData, index) => {
              return (
                <Form
                  style={{ padding: 15 }}
                  id={cardData.structValue.fields.question.stringValue}
                  key={index}
                >
                  <div>
                    <p>
                      Question:{" "}
                      {cardData.structValue.fields.question.stringValue}
                    </p>
                    {cardData.structValue.fields.optionType.stringValue ===
                    "BUTTON" ? (
                      <div
                        //  id={quickReplies.structValue.fields.payload.stringValue}
                        // key={index}
                        className={
                          "quickButtonDiv col-md-12 col-12"
                          // quickReplies.structValue.fields.size.stringValue ===
                          // "large"
                          //   ? "quickButtonDiv col-md-11 col-11"
                          //   : "quickButtonDiv col-md-6 col-6"
                        }
                      >
                        {cardData.structValue.fields.option.listValue.values.map(
                          (data, i) => (
                            <Button
                              onClick={() => {
                                // console.log("BUTTON CLICKED", data);
                                // console.log(
                                //   cardData.structValue.fields.question
                                //     .stringValue
                                // );
                                // console.log("category name", categoryName);
                                // console.log("data", data.stringValue);
                                props.setFormAnswer(
                                  categoryName,
                                  cardData.structValue.fields.question
                                    .stringValue,
                                  data.stringValue
                                );
                              }}
                              className="quickButton col-md-4 col-4 "
                              // onClick={() =>
                              //   redirect(
                              //     quickReplies.structValue.fields.link.stringValue
                              //   )
                              // }
                            >
                              {data.stringValue}
                            </Button>
                          )
                        )}
                      </div>
                    ) : null}

                    {/* Answer: <input type="text"></input> */}
                  </div>
                </Form>
              );
            })}
            {props?.Button ? (
              <Button
                onClick={async () => {
                  console.log("Submit clicked", props);
                  console.log("Button Value", ButtonValue);
                  console.log("props.buttonvalue", props.ButtonValue);
                  await onclickHandler(ButtonValue);
                }}
                className="col-md-4 col-4 "
                style={{
                  padding: 5,
                  "background-color": "#f36e24",
                  margin: 10,
                  borderRadius: 10,
                }}
              >
                {props?.Button}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

FormView.propTypes = {
  payload: PropTypes.array,
  width: PropTypes.number,
};

export default connect(null, actionTypes)(FormView);
