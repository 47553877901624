import React, { useEffect, useRef } from "react";
import Icon from "@material-ui/core/Icon";
import Messgaes from "./messages/messages";
import QuickReplyButton from "./quickReplyButtons/quickReply";
import CardView from "./cardView/cardView";
import FormView from "./FormView/FormView";
import Images from "./images/images";
import ListView from "./listView/listView";
import "./chatbotBody.css";
import { connect } from "react-redux";
import * as actionTypes from "../../../reduxStore/actions/action";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

const ChatbotBody = (props) => {
  const scrollToDiv = useRef();
  const changeHeaderRef = useRef();

  useEffect(() => {
    handleScrollToElement();
    props.getUserLocation();
    if (props.messages.length === 3 && !localStorage.getItem("uuid")) {
      const uuid = uuidv4();
      localStorage.setItem("uuid", uuid);
      const timer = setTimeout(() => {
        props.saveUserLocationandUUID();
      }, 2000);
      return () => clearTimeout(timer);
    }
    if (!sessionStorage.getItem("sessionID")) {
      sessionStorage.setItem("sessionID", uuidv4());
    }
  });

  useEffect(() => {
    handleScrollToElement();
  }, [props.messages]);

  function handleScrollToElement() {
    if (scrollToDiv) {
      scrollToDiv.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }
  function onScrollDiv() {
    if (changeHeaderRef.current.scrollTop >= 100) {
      props.changeHeaderCss({
        headerParent: "header_main",
      });
    } else if (changeHeaderRef.current.scrollTop <= 5) {
      props.changeHeaderCss({
        headerParent: "header_promo",
      });
    }
  }

  function renderOneMessage(message, index) {
    if (message.msg && message.msg.text && message.msg.text.text) {
      return (
        <Messgaes
          key={index}
          text={message.msg.text.text}
          messageBotIndex={message.botMessageIndex}
          speaker={message.speaks}
          loader={message.loader}
          time={message.time}
        />
      );
    } else if (
      message.msg.payload &&
      message.msg.payload.fields &&
      message.msg.payload.fields.quick_replies &&
      message.msg.payload.fields.quick_replies.listValue.values
    ) {
      return (
        <div className="QuickReplyMainParent" key={index}>
          <QuickReplyButton
            payload={message.msg.payload.fields.quick_replies.listValue.values}
          ></QuickReplyButton>
        </div>
      );
    } else if (
      message.msg.payload &&
      message.msg.payload.fields &&
      message.msg.payload.fields.image &&
      message.msg.payload.fields.image.listValue.values
    ) {
      return (
        <Images
          key={index}
          payload={message.msg.payload.fields.image.listValue.values}
        ></Images>
      );
    } else if (
      message.msg.payload &&
      message.msg.payload.fields &&
      message.msg.payload.fields.cardView &&
      message.msg.payload.fields.cardView.listValue.values
    ) {
      let lengthOfArray =
        message.msg.payload.fields.cardView.listValue.values.length + 1;
      let width;
      if (lengthOfArray >= 6) {
        width = lengthOfArray * 68;
      } else {
        width = lengthOfArray * 68 * 2;
      }

      return (
        <CardView
          key={index}
          messageBotIndex={message.botMessageIndex}
          payload={message.msg.payload.fields.cardView.listValue.values}
          width={width}
        ></CardView>
      );
    } else if (
      message.msg.payload &&
      message.msg.payload.fields &&
      message.msg.payload.fields.Form &&
      message.msg.payload.fields.Form.listValue.values
    ) {
      return (
        <FormView
          key={index}
          payload={message.msg.payload.fields.Form.listValue.values}
          categoryName={message.msg.payload.fields.CategoryName.stringValue}
          Button={message.msg.payload.fields.Button.stringValue}
          ButtonValue={message.msg.payload.fields.ButtonValue?.stringValue}
        >
          {" "}
        </FormView>
        // <ListView
        // key={index}
        //     payload={message.msg.payload.fields.listView.listValue.values}
        // ></ListView>
      );
    } else if (
      message.msg.payload &&
      message.msg.payload.fields &&
      message.msg.payload.fields.listView &&
      message.msg.payload.fields.listView.listValue.values
    ) {
      return (
        <ListView
          key={index}
          payload={message.msg.payload.fields.listView.listValue.values}
        ></ListView>
      );
    }
  }

  function renderMessages(stateMessages) {
    if (stateMessages) {
      return stateMessages.map((message, i) => {
        return renderOneMessage(message, i);
      });
    } else {
      return null;
    }
  }
  // componentDidCatch(error, errorInfo) {
  //     localStorage.removeItem("chatConvo");
  // }

  return (
    <div
      id="chatbot_body"
      className="chatbot_body"
      ref={changeHeaderRef}
      onScroll={onScrollDiv}
    >
      {renderMessages(props.messages)}
      <div style={{ width: "100%", height: 20 }} ref={scrollToDiv}></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: state.dfQuery.messages,
    loaderValue: state.dfQuery.showLoader,
  };
};

ChatbotBody.propTypes = {
  changeHeaderCss: PropTypes.func,
  messages: PropTypes.array,
  dfEventQuery: PropTypes.func,
};

export default connect(mapStateToProps, actionTypes)(ChatbotBody);
