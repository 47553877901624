import React from "react";
import {
	HeroSection,
	HeroSectionLeft,
	HeroSectionRight,
} from "./HeroSection.style";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actionTypes from "../../../../reduxStore/actions/action";

const index = (props) => {
	const { changeBotState, showBot } = props;

	const showChatBot = () => {
		changeBotState(!showBot);
	};
	return (
		<div>
			<HeroSection>
				<HeroSectionLeft>
					<h1>Hello, I am Sleep Disorder Diagnostic AI Assistant</h1>
					<button onClick={showChatBot}>Let's Chat</button>
					<p>
						Interact with our AI-powered assistant to help diagnose your sleep
						disorder problems.
						{/* Chat with the assistant about your sleep disorder
						and answer a few simple questions to receive the potential diagnoses
          that the assistant makes.{" "} */}
					</p>
				</HeroSectionLeft>
				<HeroSectionRight></HeroSectionRight>
			</HeroSection>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		showBot: state.chatbotReducer.showBot,
	};
};

index.propTypes = {
	showBot: PropTypes.bool,
};

// export default index;
export default connect(mapStateToProps, actionTypes)(index);
