import axios from "axios";
// const BASE_URL =
// "https://sleepdisorderbackend.uc.r.appspot.com/api/v1/dialogflow/";
// const BASE_URL =
//   "https://sleepdisorderbackend.uc.r.appspot.com/api/v1/dialogflow/";
const BASE_URL =
  "https://sleepmedicineassitantbackend-dot-sleepmedicine.uc.r.appspot.com/api/v1/dialogflow/";

let messagesArray = JSON.parse(localStorage.getItem("chatConvo"))
  ? JSON.parse(localStorage.getItem("chatConvo"))
  : [
      {
        speaks: "bot",
        loader: false,
        msg: {
          platform: "PLATFORM_UNSPECIFIED",
          text: {
            text: [
              "Welcome to Sleep Disorder Diagnostic AI Assistant. I can help diagnose your sleep disorder.",
            ],
          },
          message: "text",
        },
        botMessageIndex: 0,
      },
      {
        speaks: "bot",
        loader: false,
        msg: {
          platform: "PLATFORM_UNSPECIFIED",
          text: { text: ["Can you answer a few questions for me?"] },
          message: "text",
        },
        botMessageIndex: 1,
      },
    ];

let says;
let inputFieldType = "text";
async function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
export function userTextQuery(text) {
  return async function (dispatch) {
    says = {
      speaks: "me",
      loader: true,
      time: await formatAMPM(new Date()),
      msg: {
        text: {
          text: text,
        },
      },
    };
    messagesArray.push(says);
    // console.log("messages array", messagesArray);

    // await checkEmailValidation("type a message...", true, "#ffffff")
    dispatch({
      type: "CHANGE_DF_TEXT_QUERY_FULFILLMENT",
      payload: messagesArray,
    });
  };
}

export function myDatabaseFun(messagesArray, uuid) {
  return async function (dispatch) {
    console.log("run");
    return await axios.post(BASE_URL + "/api/databasequery", {
      messagesArray: messagesArray,
      userSessionId: `439d03a4-ed81-424a-addc-a486c876978d${uuid}`,
    });
  };
}

export function dfTestQuery(text) {
  console.log("TEXT", text);
  return async function (dispatch) {
    return await axios
      .post(BASE_URL + "api/df_text_query", {
        text: text,
        uuid: localStorage.getItem("uuid"),
        sessionID: sessionStorage.getItem("sessionID"),
        parameters: { userID: localStorage.getItem("uuid"), language: "en-IN" },
        messagesArray,
      })
      .then(async (response) => {
        console.log("here", response);
        if (response.data.action === "action.getTranscript") {
          let transResponse = await axios.post(
            "https://cogniaimassistant-egbopch3ka-uc.a.run.app/api/send_transcript",
            { email: response.data.queryText, messages: messagesArray }
          );
          console.log("TRANSCRIPT SEND", transResponse);
        }

        if (response.data.fulfillmentMessages) {
          // eslint-disable-next-line array-callback-return
          says.loader = false;
          return response.data.fulfillmentMessages.map((msg, index) => {
            if (msg.text) {
              if (
                msg.text.text[0].includes("mail") === true ||
                msg.text.text[0].includes("email") === true
              ) {
                // console.log("EMAIL")
                inputFieldType = "email";
              } else {
                // console.log("TEXT")
                inputFieldType = "text";
              }
            }
            if (msg.text && msg.text.text[0].includes("Thanks")) {
              console.log("thanks received");
              localStorage.setItem("detailsReceived", true);
            }
            says = {
              speaks: "bot",
              loader: false,
              msg: msg,
              botMessageIndex: index,
            };
            messagesArray.push(says);
            localStorage.setItem("chatConvo", JSON.stringify(messagesArray));
            console.log("HERERE");

            // dispatch(
            //   myDatabaseFun(messagesArray, localStorage.getItem("uuid"))
            // );
            return dispatch({
              type: "CHANGE_DF_TEXT_QUERY_FULFILLMENT",
              payload: messagesArray,
              inputFieldType: inputFieldType,
            });
          });
        } else if (response.data.webhookPayload) {
          dispatch({
            type: "CHANGE_DF_TEXT_QUERY_WEBHOOK",
            payload: response.data.webhookPayload,
          });
        }
      });
  };
}

export function dfEventQuery(event) {
  // console.log("EVENTTTTTTTT")
  return async function (dispatch) {
    return await axios
      .post(BASE_URL + "api/df_event_query", {
        event: event,
        uuid: localStorage.getItem("uuid"),
        parameters: { uuid: localStorage.getItem("uuid"), language: "en-US" },
        location: {
          country: localStorage.getItem("countryName"),
          state: localStorage.getItem("state"),
          city: localStorage.getItem("city"),
          IPv4: localStorage.getItem("IPv4"),
        },
      })
      .then(async (response) => {
        // console.log("EVENT QUERY", response)
        let respEvent = await response.data.fulfillmentMessages;
        says.loader = false;
        return await respEvent.map((msg, index) => {
          says = {
            speaks: "bot",
            loader: false,
            msg: msg,
            botMessageIndex: index,
          };
          messagesArray.push(says);

          // says.loaded = "true";

          return dispatch({
            type: "CHANGE_DF_EVENT_QUERY",
            payload: messagesArray,
          });
        });
      });
  };
}

export function changeHeaderCss(event) {
  return async function (dispatch) {
    dispatch({
      type: "CHANGE_HEADER_CSS",
      payload: event,
    });
  };
}

export function changeMessageArray() {
  return async function (dispatch) {
    messagesArray = messagesArray.filter((objects) => {
      if (
        objects.speaks === "bot" &&
        objects.msg &&
        objects.msg.payload &&
        objects.msg.payload.fields &&
        objects.msg.payload.fields.quick_replies
      ) {
        return null;
      } else {
        return objects;
      }
    });
    dispatch({
      type: "CHANGE_MESSAGE_ARRAY",
      payload: messagesArray,
    });
  };
}

export function changeBotState(value) {
  return async function (dispatch) {
    dispatch({
      type: "CHANGE_BOT_STATE",
      payload: value,
    });
  };
}

export function getUserLocation() {
  return async function (dispatch) {
    await axios
      .get("https://geolocation-db.com/json/")
      .then(async (response, err) => {
        localStorage.setItem("countryName", response.data.country_name);
        localStorage.setItem("state", response.data.state);
        localStorage.setItem("city", response.data.city);
        localStorage.setItem("IPv4", response.data.IPv4);
      });
  };
}

export function saveUserLocationandUUID() {
  // console.log("DATA SAVED IN DB")
  return async function (dispatch) {
    await axios.post(BASE_URL + "api/saveUserDataLocation", {
      uuid: localStorage.getItem("uuid"),
      location: {
        country: localStorage.getItem("countryName"),
        state: localStorage.getItem("state"),
        city: localStorage.getItem("city"),
        IPv4: localStorage.getItem("IPv4"),
      },
    });
  };
}

export function clearConversation() {
  messagesArray = [
    {
      speaks: "bot",
      loader: false,
      msg: {
        platform: "PLATFORM_UNSPECIFIED",
        text: {
          text: [
            "Welcome to Sleep Disorder Diagnostic AI Assistant. I can help diagnose your sleep disorder.",
          ],
        },
        message: "text",
      },
      botMessageIndex: 0,
    },
    {
      speaks: "bot",
      loader: false,
      msg: {
        platform: "PLATFORM_UNSPECIFIED",
        text: { text: ["Can you answer a few questions for me?"] },
        message: "text",
      },
      botMessageIndex: 1,
    },
    {
      speaks: "bot",
      loader: false,
      msg: {
        platform: "PLATFORM_UNSPECIFIED",
        payload: {
          fields: {
            quick_replies: {
              listValue: {
                values: [
                  {
                    structValue: {
                      fields: {
                        size: {
                          stringValue: "small",
                          kind: "stringValue",
                        },
                        payload: {
                          stringValue: "name",
                          kind: "stringValue",
                        },
                        text: {
                          stringValue: "Yes",
                          kind: "stringValue",
                        },
                        event: {
                          stringValue: "name",
                          kind: "stringvalue",
                        },
                      },
                    },
                    kind: "structValue",
                  },
                  {
                    structValue: {
                      fields: {
                        size: {
                          stringValue: "small",
                          kind: "stringValue",
                        },
                        payload: {
                          stringValue: "Declined",
                          kind: "stringValue",
                        },
                        text: {
                          stringValue: "No",
                          kind: "stringValue",
                        },
                        event: {
                          stringValue: "DECLINED",
                          kind: "stringvalue",
                        },
                      },
                    },
                    kind: "structValue",
                  },
                ],
              },
              kind: "listValue",
            },
          },
        },
        message: "payload",
      },
      botMessageIndex: 2,
    },
  ];

  return async function (dispatch) {
    dispatch({
      type: "Clear_Conversation",
      payload: messagesArray,
    });
  };
}
export function setInputType() {
  return async function (dispatch) {
    console.log("messages array", messagesArray);

    dispatch({
      type: "CHANGE_DF_TEXT_QUERY_FULFILLMENT",
      payload: messagesArray,
      inputFieldType: "text",
    });
  };
}

export function checkEmailValidation(placeholder, inputState, setFooterColor) {
  return async function (dispatch) {
    dispatch({
      type: "CHECK_EMAIL_VALIDATION",
      payload: {
        placeholder: placeholder,
        inputState: inputState,
        setFooterColor: setFooterColor,
      },
    });
  };
}

export function setFormAnswer(formTopic, formQuestion, formAnswer) {
  console.log(formTopic);
  console.log(formQuestion);
  console.log(formAnswer);
  return async function (dispatch) {
    dispatch({
      type: "SET_FORM_ANSWER",
      payload: {
        formTopic: formTopic,
        formQuestion: formQuestion,
        formAnswer: formAnswer,
      },
    });
  };
}
