import React from "react";
import {
  LogoImage,
  TopHeader,
  LogoHeading,
  LogoMainHeading,
  LogoSubHeading,
  ExtraSpace,
} from "./TopBanner.style";
import logoImage from "../../../../assests/images/chatbot/EditedLogo.png";

const index = () => {
  return (
    <div>
      <TopHeader>
        <LogoImage>
          <img src={logoImage} />
        </LogoImage>
        <LogoHeading>
          <LogoMainHeading>
            {/* <p id="heading">Sleep Disorder Diagnostic AI Assistant</p> */}
            {/* <p id="heading">Somnology Tech LLC</p> */}
            {/* <img
              src={require("../../../../assests/images/chatbot/sleep-institute-logo-no-icon-2020-75.png")}
              alt=""
            /> */}
          </LogoMainHeading>
          <LogoSubHeading>
            <strong></strong>
          </LogoSubHeading>
        </LogoHeading>
      </TopHeader>
    </div>
  );
};

export default index;
