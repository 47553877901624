import React, { useState } from "react";
import "./header.css";
import { connect } from "react-redux";
import * as actionTypes from "../../../reduxStore/actions/action";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import { ListGroup } from "react-bootstrap";

const Header = (props) => {
	const { headerClasses } = props;
	const [listviewState, setListview] = useState(false);
	const [transCss, setTransCss] = useState("trancription_icon");

	function onMenuClick() {
		setListview(!listviewState);
		if (listviewState === true) {
			// setTransCss("trancription_icon_Css");
			props.changeHeaderCss({
				headerParent: "header_main",
			});
		} else {
			// setTransCss("trancription_icon");
			props.changeHeaderCss({
				headerParent: "header_promo",
			});
		}
	}
	function handleOnClickHeader() {
		props.changeBotState(!props.showBot);
	}
	function onGetTranscript() {
		setListview(!listviewState);
		setTransCss("trancription_icon");
		props.changeMessageArray();
		props.userTextQuery("transcript");
		props.dfTestQuery("transcript");
	}
	function onClearConvo() {
		localStorage.clear();
		setTransCss("trancription_icon");
		props.clearConversation();
		props.setInputType();

		setListview(!listviewState);
	}
	return (
		<div className={headerClasses.headerParent}>
			<div className="header_container_inner_promo">
				<div className="header_img_div">
					<img
						alt="imgChatbot"
						className="header_promo_img"
						// src="https://www.cogniaim.com/webtheme/img/products/chatbot_icon.png"
						// src={require("../../../assests/images/chatbot/SomnologyLogo.png")}
						src={require("../../../assests/images/chatbot/NewLogo.png")}
					></img>
				</div>
				<div id="trancription_icon" className={transCss} onClick={onMenuClick}>
					{listviewState === true ? (
						<Icon style={{ fontSize: 27 }}>cancel_presentation_icon</Icon>
					) : (
						<Icon style={{ fontSize: 27 }}>menu_icon</Icon>
					)}
				</div>
				<div
					id="drop_down_icon"
					className="drop_down_icon"
					onClick={handleOnClickHeader}
				>
					<Icon>expand_more</Icon>
					{/* expand_more */}
				</div>

				<div className="header_title_promo">
					{/* <h6 className="h5Style_promo">
            Sleep Disorder Diagnostic AI Assistant
          </h6>
          <h6 className="h6Style_promo">Somnology Tech LLC</h6> */}
				</div>
				{listviewState === true ? (
					<ListGroup className="listViewMenu">
						<div className="items">
							<ListGroup.Item
								style={{ paddingTop: 6, paddingBottom: 6, display: "flex" }}
								onClick={onClearConvo}
							>
								<Icon style={{ verticalAlign: "middle", marginRight: 10 }}>
									clear_all
								</Icon>{" "}
								Clear Conversation
							</ListGroup.Item>
						</div>
					</ListGroup>
				) : null}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		headerClasses: state.changeHeaderCss.newCss,
		showBot: state.chatbotReducer.showBot,
	};
};

Header.propTypes = {
	showBot: PropTypes.bool,
	changeBotState: PropTypes.func,
	headerClasses: PropTypes.object,
};

export default connect(mapStateToProps, actionTypes)(Header);
